.voting-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.nominados-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.tofu-description {
    font-family: "Playfair Display", serif; /* Usa la misma fuente que el resto de la página */
    font-size: 1.8rem; /* Ajusta el tamaño de fuente según necesidad */
    font-weight: bold;
    color: #ffda95; /* Color dorado suave */
    text-shadow: 0 0 3px #ffcc00, 0 0 5px #ffcc00; /* Efecto de brillo dorado */
    text-align: center; /* Centra el texto horizontalmente */
    margin: 20px 40px; /* Margen superior/inferior y lateral */
    line-height: 1.5; /* Aumenta el interlineado para mejor legibilidad */
    padding: 10px 20px; /* Espaciado interno para crear separación visual */
    border-radius: 8px; /* Bordes redondeados */
    background: rgba(0, 0, 0, 0.5); /* Fondo semitransparente para destacar el texto */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Sombra suave */
}
  

.nominados-container.transitioning {
    opacity: 0;
}

.VotingTitle {
    font-family: "Playfair Display", serif;
    font-size: 4rem;
    font-weight: bold;
    color: #ffda95;
    text-shadow: 0 0 3px #ffcc00, 0 0 5px #ffcc00;
    margin-bottom: 20px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.VotingTitle.transitioning {
    opacity: 0;
}

.subtitle {
    font-family: "Playfair Display", serif;
    font-weight: bold;
    color: #ffda95;
    text-shadow: 0 0 3px #ffcc00, 0 0 5px #ffcc00;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.subtitle.transitioning {
    opacity: 0;
}

.nominado {
    text-align: center;
    position: relative;
    font-family: "Playfair Display", serif;
    font-weight: bold;
    color: #ffda95;
    text-shadow: 0 0 3px #ffcc00, 0 0 5px #ffcc00;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.nominado.transitioning {
    opacity: 0;
}

.nominado img {
    width: 300px;
    height: 200px;
    border-radius: 5%;
    opacity: 1;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.nominado img.transitioning {
    opacity: 0;
    transform: scale(0.9);
}

.nominado img:hover {
    filter: brightness(1.2);
    transform: scale(1.05);
    transition: transform 0.3s ease, filter 0.3s ease;
}

.nominado-image-primero{
    margin-left: 50px;
    width: 300px;
    height: 200px;
    border-radius: 5%;
    opacity: 1;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.nominado-image-primero:hover{
    filter: brightness(1.2);
    transform: scale(1.05);
    transition: transform 0.3s ease, filter 0.3s ease;
}
.nominado-image-primero.transitioning{
    opacity: 0;
    transform: scale(0.9);
}

.nominado-image{
    width: 200px;
    height: 100px;
    border-radius: 5%;
    opacity: 1;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.nominado-image:hover{
    filter: brightness(1.2);
    transform: scale(1.05);
    transition: transform 0.3s ease, filter 0.3s ease;
}
.nominado-image.transitioning{
    opacity: 0;
    transform: scale(0.9);
}
/* Otros estilos que ya tienes */

.vote-button {
    background-color: white;
    border: 1px solid black;
    padding: 5px 10px;
    margin-top: 5px;
}

.vote-button.selected {
    background-color: green;
    color: white;
}

.button-voting {
    font-family: "Playfair Display", serif;
    background: linear-gradient(90deg, #FFDD44, #FF9933);
    border: none;
    color: #333;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.button-voting:hover {
    background: linear-gradient(90deg, #FF9933, #c48900);
    transform: scale(1.05);
}

.videoButton {
    font-family: "Playfair Display", serif;
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    color: #FFDD44;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.videoButton:hover {
    background-color: rgb(251, 255, 0);
    transform: scale(1.05);
    color: #000;
}

.button-next-back {
    font-family: "Playfair Display", serif;
    background: linear-gradient(90deg, #FFDD44, #FF9933);
    border: none;
    color: #333;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}


.final-vote-button-container {
    display: flex;
    flex-direction: column; /* Alinea el mensaje y el botón verticalmente */
    align-items: center; /* Centra los elementos */
    justify-content: center; /* Centra los elementos en su contenedor */
    margin-top: 20px;
    margin-bottom: 20px; /* Espacio debajo */
    gap: 15px; /* Espacio consistente entre el mensaje y el botón */
}

.message {
    color: #ffcc00; /* Amarillo para consistencia */
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    visibility: hidden; /* Oculta el mensaje por defecto, pero ocupa espacio */
    opacity: 0; /* Inicialmente transparente */
    transition: opacity 0.3s ease-in-out; /* Suaviza la transición al mostrar/ocultar */
    min-height: 1.5rem; /* Reserva espacio incluso cuando no hay mensaje */
}

.message.visible {
    visibility: visible; /* Hace visible el mensaje */
    opacity: 1; /* Hace el mensaje opaco */
}

.button-final-vote {
    font-family: "Playfair Display", serif;
    background: linear-gradient(45deg, #bb4444, #000000);
    border: none;
    color: #ffffff;
    padding: 10px 25px;
    width: 250px;
    height: 50px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.button-final-vote:hover {
    background: linear-gradient(90deg, #000, #bb4444);
    transform: scale(1.05);
}

.button-next-back:hover {
    background: linear-gradient(90deg, #FF9933, #c48900);
    transform: scale(1.05);
}

.button-next-back:active {
    background: linear-gradient(90deg, #c48900, #FF9933);
    transform: scale(0.95);
}

.navigation-buttons {
    position: absolute;
    bottom: 40px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    transform: translate(-50%, -50%);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ff0000; /* Rojo sólido para mejor visibilidad */
    color: white;
    border: none;
    width: 25px; /* Tamaño reducido para ser menos intrusivo */
    height: 25px; /* Tamaño reducido para ser menos intrusivo */
    border-radius: 50%;
    font-size: 16px; /* Tamaño del texto de la 'X' */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease, transform 0.2s ease;
    z-index: 1500; /* Asegurarse de que esté por encima del resto del contenido */
}

.modal-close-button:hover {
    background: #cc0000; /* Un rojo más oscuro para el efecto hover */
    transform: scale(1.1); /* Aumentar ligeramente el tamaño al pasar el ratón */
}

.modal-video {
    width: 100%;
    height: auto;
}



.nominado select {
    width: auto;
    padding: 5px 10px;
    border-radius: 8px;
    border: 2px solid #ffcc00;
    background-color: #f0f8f5;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    outline: none;
    appearance: none;
    cursor: pointer;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nominado-options {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2000;
}

.progress-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.progress-dot.active {
    background-color: #ffcc00;
    transform: scale(1.5);
}

/* Estilo para el selector personalizado */
.vote-select {
    font-family: "Playfair Display", serif;
    font-weight: bold;
    color: #333;
    background: linear-gradient(90deg, #FFDD44, #FF9933);
    border: 2px solid #ffcc00;
    border-radius: 8px;
    padding: 10px;
    appearance: none;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, transform 0.3s ease, background 0.3s ease;
    outline: none;
    text-align: center;
    text-shadow: 0 0 3px #ffcc00, 0 0 5px #ffcc00;
}

.vote-select:hover {
    background: linear-gradient(90deg, #FF9933, #c48900);
    transform: scale(1.05);
}

.vote-select:focus {
    border-color: #c48900;
}


.vote-selection{
    text-align: center;
    position: relative;
    font-family: "Playfair Display", serif;
    font-weight: bold;
    color: #ffda95;
    text-shadow: 0 0 3px #ffcc00, 0 0 5px #ffcc00;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.voting-container.goodbye-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
  }
  
  .goodbye-content {
    text-align: center;
  }
  