/* Estilo general para la página de login */
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100vh; /* Usa el 100% de la altura de la ventana */
  overflow: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


.message-container{
  color: #eeff00
}

/* Contenedor del formulario */
.login-container {
  font-family: "Playfair Display", serif;
  width: 350px;
  padding: 2rem;
  background-color: #000000; /* Fondo negro mate */
  border-radius: 8px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2); /* Sombra más suave */
  backdrop-filter: blur(5px); /* Efecto de desenfoque en el fondo */
  border: 3px solid #b8860b; /* Borde dorado oscuro */
}

.video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1; /* Asegura que el video esté detrás del contenido */
  transform: translate(-50%, -50%);
}

/* Encabezado del formulario */
.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #b8860b; /* Letras doradas */
}

/* Campo de entrada */
.input-field {
  position: relative;
  margin-bottom: 1rem;
  
}

.input-field input {
  width: 93%; /* Cambiado a 100% para usar todo el ancho */
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

/* Icono para mostrar/ocultar contraseña */
.eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #888;
  cursor: pointer;
}

/* Enlace para recuperar contraseña */
.forgot-pass {
  display: block;
  text-align: right;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  color: #b8860b;
  text-decoration: none;
  
}

.forgot-pass:hover {
  text-decoration: underline;
}

/* Estilo para los botones */
.button-field {
  margin-bottom: 1.5rem;
}

.button-field button {
  width: 100%;
  padding: 0.75rem;
  background-color: #b8860b;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  font-family: "Playfair Display", serif;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-field button:hover {
  background-color: #a6750a; /* Color un poco más oscuro */
  transform: scale(1.05); /* Aumenta el tamaño un poco */
}

/* Estilo para los enlaces de formulario */
.form-link {
  text-align: center;
  color: #ffffff;
  margin-bottom: 1.5rem;
}

.form-link span {
  font-size: 0.9rem;
}

.form-link a {
  color: #b8860b;
  text-decoration: none;
  font-weight: bold;
}

.form-link a:hover {
  text-decoration: underline;
}

/* Separador */
.divider {
  text-align: center;
  margin: 1.5rem 0;
  color: #b8860b; /* Letras doradas */
}

/* Estilo para el login social */
.social-login {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.facebook-login, .google-login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.75rem;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #ccc;
  font-family: "Playfair Display", serif;
  transition: background-color 0.3s ease;
}

/* Estilos para Facebook */
.facebook-login i {
  margin-right: 0.5rem;
  font-size: 20px;
}

/* Estilos para Google */
.google-login {
  background-color: white;
  color: #333;
}

.google-login img {
  width: 20px;
  margin-right: 0.5rem;
}

/* Efecto hover para botones de social login */
.google-login:hover, .facebook-login:hover {
  background-color: #9b9da0;
  transform: scale(1.05)
}


