/* css/PasswordPrompt.css */

.background {
  position: relative;
  width: 100%;
  height: 100vh; /* Usa el 100% de la altura de la ventana */
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1; /* Asegura que el video esté detrás del contenido */
  transform: translate(-50%, -50%);
}

.password-container {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 10; /* Asegura que el texto esté por encima del video */
}

.inputPass {
  font-family: "Playfair Display", serif;
  box-shadow:
  0 0 3px #000,
  0 0 5px #000;
}

.accesButton{
  font-family: "Playfair Display", serif;
}

.title {
  font-family: "Playfair Display", serif;
  font-size: 6rem; /* Ajusta el tamaño de la fuente */
  font-weight: bold;
  color: #ffda95; /* Color dorado */
  text-shadow: 
  0 0 3px #ffcc00,   /* Sombra menos difusa */
  0 0 5px #ffcc00;   /* Solo dos sombras para menos intensidad */
  margin-bottom: 20px; /* Espacio entre el título y el formulario */
}

.subtitle {
  font-family: "Playfair Display", serif;
  font-weight: bold;
  color: #ffda95; /* Color dorado */
  text-shadow: 
  0 0 3px #ffcc00,   /* Sombra menos difusa */
  0 0 5px #ffcc00;   /* Solo dos sombras para menos intensidad */
}

h2 {
  margin-bottom: 20px; /* Espacio entre el subtítulo y el formulario */
}

input {
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ffcc00; /* Cambia el color si deseas */
  cursor: pointer;
}


button:hover {
  background-color: #e6b800; /* Cambia el color del botón al pasar el cursor */
}

.error-message {
  color: red; /* Color del mensaje de error */
  margin-top: 10px; /* Espacio entre el botón y el mensaje de error */
  font-weight: bold; /* Negrita para el mensaje */
}
